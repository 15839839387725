<template>
  <div ref="chatbox">
    <!-- update prescription -->
    <modal
      id="updatedPrescription"
      ref="modal"
      size="md"
      centered
      hide-header
      hide-footer
    >
      <div
        class="modal-header intake-header"
        style="padding: 0; border: 0"
      >
        <b-container
          fluid
          class="pl-0 pr-0"
        >
          <b-row>
            <b-col
              cols="12"
              class="text-right"
            >
              <b-button
                size="sm"
                variant="link"
                @click="$bvModal.hide('updatedPrescription')"
              >
                <b-icon
                  icon="x-circle-fill"
                  variant="primary"
                />
              </b-button>
            </b-col>
          </b-row>
        </b-container>
      </div>
      <h2 class="heading text-primary mb-2">
        Updated Treatment
      </h2>

      <p v-if="prescriptionMessage.custommessage">
        The price of
        {{ prescriptionMessage.custommessage.message.productname }} treatment
        has changed from ${{
          prescriptionMessage.custommessage.message.pricefrom
        }}
        to ${{ prescriptionMessage.custommessage.message.priceto }}.
      </p>

      <div
        v-if="prescriptionMessage.custommessage"
        class="products"
      >
        <div class="product-img">
          <img
            :src="prescriptionMessage.custommessage.message.image"
            alt=""
          >
        </div>
        <ul class="product-content">
          <li>
            <h2 class="mb-2 sub-text bold-font">
              {{ prescriptionMessage.custommessage.message.productname }}
            </h2>
            <p
              v-line-clamp="2"
              class="mb-0"
            >
              {{ prescriptionMessage.custommessage.message.description }}
            </p>
          </li>
          <li>
            <p class="mb-0 text-primary">
              {{ prescriptionMessage.custommessage.message.pricestatement }}
            </p>
          </li>
        </ul>
      </div>
    </modal>

    <!-- 10 Week Notice Modal -->

    <FollowupV5
      ref="modalv5"
      :key="followupV5Key"
      :messages="messages"
      :patientid="userid"
      @hide="followupV5Key += 1"
    />

    <modal
      id="weekNotice"
      ref="modal"
      size="xl"
      dialog-class="ten-week"
      content-class="ten-week-modal"
      centered
      hide-header
      hide-footer
      no-close-on-backdrop
      no-close-on-esc
    >
      <b-row
        align-v="stretch"
        class="ten-week-modal-content px-5"
      >
        <b-col
          cols="12"
          align-self="start"
          class="mt-3"
        >
          <b-row align-v="center">
            <b-col cols="1">
              <b-button
                v-if="followUpMessage.length > 0 && followUpMessage[0].id != 8"
                class="back-btn"
                size="lg"
                variant="link"
                style="min-width: inherit"
                @click="getPreviousFollowUpQuestion"
              >
                <img
                  src="@/assets/images/small_back_arrow_black.svg"
                >
              </b-button>
            </b-col>
            <b-col class="d-flex justify-content-center">
              <img
                class="d-none d-md-block"
                src="@/assets/images/logo_black.svg"
                alt=""
              >
              <img
                class="d-block d-md-none"
                src="@/assets/images/logo_black.svg"
                width="120"
                alt=""
              >
            </b-col>
            <!-- dont remove this -->
            <b-col cols="1" />
          </b-row>
        </b-col>
        <b-col
          cols="12"
          align-self="center"
        >
          <WeekNone
            v-if="followUpType == 'nothing'"
            :question="followUpMessage[0]"
            :composite="composite"
            @submitAnswer="answerFollowUpQuestion"
          />
          <WeekSingle
            v-if="followUpType == 'single'"
            :question="followUpMessage[0]"
            :composite="composite"
            @submitAnswer="answerFollowUpQuestion"
          />
          <WeekText
            v-if="followUpType == 'text'"
            :question="followUpMessage[0]"
            :composite="composite"
            @submitAnswer="answerFollowUpQuestion"
          />

          <!-- Composites -->
          <div
            v-if="composite"
            class="mt-4"
          >
            <WeekText
              v-if="
                composite &&
                  secondQuestionBody.answertype == 'text' &&
                  followUpMessage[1].requiredifvalue == firstAnswer
              "
              :question="secondQuestionBody"
              @submitAnswer="answerFollowUpQuestion"
            />
          </div>
        </b-col>
        <b-col
          align-self="end"
          class="text-center onboarding-footer"
          style="margin-bottom: -1.5rem"
        >
          <b>Treatment Review</b>
        </b-col>
      </b-row>
    </modal>

    <div
      id="chat-headline"
      class="chat-headlines mx-auto"
    >
      <!-- getMemberName(messages[0]) -->
      <div style="max-width: 1150px">
        <div
          v-if="getDoctorName(userid)"
          class="mb-0 d-flex"
        >
          <div class="text-right pr-3 d-flex align-items-center">
            <b-avatar :src="getMemberProfileUrl(getDoctorID(userid))" />
          </div>
          <div>
            <b>{{ getDoctorName(userid) }}</b>
            <br>
            <span class="text-muted">Winona Physician</span>
          </div>
        </div>
        <p v-else></p>
      </div>
    </div>

    <ul class="chat-holder list-unstyled mb-0">
      <li :class="['chat-box-container', imgUpload ? 'uploading-img' : '']">
        <div
          id="chat-box"
          :class="['chat-box', mobileClass]"
          :style="{
            height: threadHeight + 'px',
            overflow: 'auto',
          }"
          @scroll.passive="messageScroll"
        >
          <div class="chat-holder">
            <template>
              <div class="chat">
                <div
                  v-for="(messages, sentAt, index) in groupMessage"
                  :key="sentAt"
                >
                  <div class="text-center">
                    {{ formattedTimestamp(sentAt) }}
                  </div>
                  <div
                    v-for="(chat, chatIdx) in messages"
                    :key="chatIdx"
                  >
                    <div
                      :class="[
                        chat.userid == userid ? 'patient' : 'doctor',
                        'chat-bubble',
                      ]"
                    >
                      <div
                        v-if="
                          chat.message &&
                            chat.type != 'prescription_confirmation' &&
                            chat.type != 'prescription_followup' &&
                            chat.type != 'reassignment_transfer'
                        "
                        :class="[
                          chat.userid == userid ? 'patient' : 'doctor',
                          'msg',
                        ]"
                      >
                        <p class="mb-0 d-letter-spacing">
                          <span v-html-safe="chat.message"></span>
                        </p>
                      </div>

                      <b-row
                        v-for="(group, groupIndex) in groupBy(
                          chat.attachment,
                          amountToGroupBy
                        )"
                        :key="groupIndex"
                        no-gutters
                        :class="[
                          chat.userid == userid ? 'patient' : 'doctor',
                          'msg',
                          'p-0',
                        ]"
                        style="background-color: transparent"
                      >
                        <template v-for="(attach, i) in group">
                          <b-col
                            v-if="
                              attach.type.includes('image') &&
                                !['tiff', 'heic', 'svg', 'x-xbitmap'].some(
                                  (type) => attach.type.includes(type)
                                )
                            "
                            :key="attach + i"
                          >
                            <b-img
                              thumbnail
                              fluid
                              :src="attach.url"
                            />
                          </b-col>
                          <template v-else>
                            <a
                              :key="attach + i"
                              :href="attach.url"
                              variant="link"
                              download
                              target="_blank"
                              class="p-2 d-block d-letter-spacing"
                            >
                              <b-icon
                                icon="arrow-down-circle-fill"
                                variant="primary"
                              />
                              {{ attach.filename }}
                            </a>
                          </template>
                        </template>
                      </b-row>
                    </div>
                    <div
                      v-if="
                        chat.type != 'prescription_confirmation' &&
                          chat.type != 'prescription_followup' &&
                          chat.type != 'reassignment_transfer'
                      "
                      :class="[
                        chat.userid != userid ? 'is-doctor' : '',
                        'chat-img',
                      ]"
                    >
                      <div
                        v-if="chat.userid != userid"
                        class="img-holder"
                      >
                        <img
                          :src="getMemberProfileUrl(chat.userid)"
                          alt=""
                        >
                      </div>
                      <div class="doctor-info">
                        <h3
                          v-if="chat.userid != userid"
                          class="mb-0 bold d-letter-spacing"
                          style="color: #333333"
                        >
                          {{ getMemberName(chat.userid) }}
                        </h3>
                        <small
                          class="text-muted d-letter-spacing"
                          style="font-size: 12px"
                        >{{
                          moment
                            .unix(chat.sentat)
                            .format("MMM DD, YYYY hh:mm A")
                        }}</small>
                      </div>
                    </div>

                    <b-card
                      v-if="
                        chat.message != 'null' &&
                          chat.type === 'prescription_followup'
                      "
                      class="new-prescription text-center"
                    >
                      <p class="sub-text">
                        {{
                          chat.custommessage.message.systemreply != undefined
                            ? chat.custommessage.message.systemreply
                            : chat.custommessage.message.text
                        }}
                      </p>
                      <b-button
                        v-if="
                          chat.message != 'null' &&
                            chat.type === 'prescription_followup' &&
                            chat.custommessage.message.systemreply == undefined
                        "
                        class="pending-btn"
                        pill
                        variant="info"
                        style="min-width: 200px"
                        @click="onFollowUpStart(chat)"
                      >
                        {{
                          chat.custommessage.message.systemreply != undefined
                            ? "View answers"
                            : "Continue"
                        }}
                      </b-button>
                    </b-card>

                    <b-card
                      v-if="
                        chat.message != 'null' &&
                          chat.type === 'prescription_confirmation'
                      "
                      class="new-prescription text-center"
                    >
                      <p
                        v-if="chat.actionstatus === 'PENDING'"
                        class="sub-text"
                      >
                        Your treatment has been changed, please review
                        <a
                          v-b-modal.updatedPrescription
                          href="#"
                          @click="setPrescriptionMessage(chat.id)"
                        >
                          here.
                        </a>
                      </p>

                      <p
                        v-if="chat.actionstatus === 'ACCEPTED'"
                        class="sub-text"
                      >
                        You've accepted your treatment, please review
                        <a
                          v-b-modal.updatedPrescription
                          href="#"
                          @click="setPrescriptionMessage(chat.id)"
                        >
                          here.
                        </a>
                      </p>

                      <p
                        v-if="chat.actionstatus === 'DECLINED'"
                        class="sub-text"
                      >
                        You've declined your treatment, please review
                        <a
                          v-b-modal.updatedPrescription
                          href="#"
                          @click="setPrescriptionMessage(chat.id)"
                        >
                          here.
                        </a>
                      </p>

                      <b-row
                        v-if="chat.actionstatus === 'PENDING'"
                        align-h="center"
                      >
                        <b-col
                          cols="12"
                          md="6"
                          class="text-right"
                        >
                          <b-button
                            class="pending-btn accept"
                            pill
                            variant="outline-info"
                            style="min-width: 200px"
                            @click="performMessageAction(chat.id, 'ACCEPT')"
                          >
                            ACCEPT
                          </b-button>
                        </b-col>
                        <b-col
                          cols="12"
                          md="6"
                          class="text-left"
                        >
                          <b-button
                            class="pending-btn decline"
                            pill
                            variant="info"
                            style="min-width: 200px"
                            @click="performMessageAction(chat.id, 'DECLINE')"
                          >
                            DECLINE
                          </b-button>
                        </b-col>
                      </b-row>
                    </b-card>

                    <b-card
                      v-if="
                        chat.message != 'null' &&
                          chat.type === 'reassignment_transfer'
                      "
                      class="new-prescription text-center"
                    >
                      <p class="sub-text mb-1">
                        You have been reassigned to Dr.
                        {{ chat.custommessage.message.doctorto }}
                      </p>
                      <p
                        v-if="
                          chat.custommessage.message.assignmenttype ===
                            'TEMPORARY' &&
                            chat.custommessage.message.reassignmentstatus !==
                            'RETURNED'
                        "
                        class="mb-0"
                      >
                        {{
                          moment
                            .unix(chat.custommessage.message.startdatestamp)
                            .format("MMM DD, YYYY hh:mm A")
                        }}
                        to
                        {{
                          moment
                            .unix(chat.custommessage.message.enddatestamp)
                            .format("MMM DD, YYYY hh:mm A")
                        }}
                      </p>
                    </b-card>
                  </div>
                  <div
                    v-if="
                      index === Object.keys(groupMessage).length - 1 &&
                        isUploading
                    "
                    class="text-center"
                  >
                    <b-spinner big />
                  </div>
                </div>
              </div>
            </template>
            <div
              v-if="!Boolean(getDoctorName(userid)) && !loadingMessage"
              class="text-center py-1"
              style="background-color: #ffecca; color: #ff8d40"
            >
              The doctor will respond to your chats once you are verified
            </div>
          </div>
        </div>

        <b-alert
          :show="alert.connection.show"
          variant="danger"
          class="text-center"
        >
          You are currently disconnected. Click
          <a
            href="javascript:;"
            class="text-light"
            @click="reconnectApollo"
          ><u><b>reconnect</b></u></a>
          to continue messaging.
        </b-alert>

        <div
          v-if="isConversationRestricted"
          class="message text-center"
        >
          <p class="mb-0">
            Your ability to message this doctor has been restricted due to a
            violation of our code of conduct.
          </p>
          <p class="mb-0">
            Please seek medical assistance from another healthcare provider.
          </p>
        </div>
      </li>
    </ul>

    <div
      v-show="!isConversationRestricted"
      id="message-input-area"
      ref="sendMessage"
      class="message"
    >
      <b-form @submit.prevent="sendMessage()">
        <b-container class="px-0">
          <div class="message-holder">
            <div class="textarea-box">
              <textarea
                ref="typeMessage"
                v-model="newMessage"
                class="chat-textbox"
                placeholder="Type a message"
                @blur="hideLabel()"
              ></textarea>
              <div>
                <a
                  href="#"
                  class="p-3 h-100"
                  @click="openUpload"
                >
                  <img
                    src="@/assets/images/paperclip_round.svg"
                    alt=""
                  >
                </a>
                <input
                  ref="fileInput"
                  type="file"
                  f
                  style="display: none"
                  multiple
                  @change="handleFilesUpload()"
                >
              </div>
            </div>
            <div class="button-holder d-flex align-items-center">
              <div class="d-none d-lg-flex">
                <b-button
                  id="send-button"
                  pill
                  block
                  type="submit"
                  variant="link"
                  :disabled="hasError || isSending || isMessageBodyEmpty"
                  style="width: 50px; height: 46px"
                >
                  <b-spinner
                    v-if="isSending"
                    small
                  />

                  <img
                    v-else
                    src="@/assets/images/send.svg"
                    alt=""
                  >
                </b-button>
              </div>
              <b-tooltip
                v-if="!isMessageBodyEmpty && !isSending"
                target="send-button"
              >
                Send message
              </b-tooltip>
              <div class="d-lg-none d-flex">
                <b-button
                  pill
                  block
                  type="submit"
                  variant="link"
                  :disabled="hasError || isSending || isMessageBodyEmpty"
                >
                  <b-spinner
                    v-if="isSending"
                    small
                  />
                  <img
                    v-else
                    src="@/assets/images/send.svg"
                    alt=""
                  >
                </b-button>
              </div>
            </div>
          </div>
        </b-container>
      </b-form>
      <b-container
        v-if="attachments.length > 0"
        ref="uploadImg"
        class="pre-upload"
      >
        <div
          v-for="(attachment, index) in attachments"
          :key="index"
          class="img-holder"
        >
          <b-icon
            icon="x-circle-fill"
            class="close-btn"
            @click="removeFile(index)"
          />
          <img
            v-if="viewImage(attachment)"
            :src="attachment.url"
            :alt="attachment.name"
          >
          <img
            v-else-if="!viewImage(attachment)"
            src="@/assets/images/pdf-logo.png"
            :alt="attachment.name"
          >
          <div v-else>
            <svg
              v-if="
                !attachment.type.includes('image') ||
                  attachment.type.includes('heic') ||
                  attachment.type.includes('tiff') ||
                  attachment.type.includes('x-xbitmap')
              "
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                d="M6 2c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8.83c0-.53-.21-1.04-.59-1.41l-4.83-4.83c-.37-.38-.88-.59-1.41-.59H6zm7 6V3.5L18.5 9H14c-.55 0-1-.45-1-1z"
              />
            </svg>
          </div>
        </div>
      </b-container>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { mapState, mapGetters } from 'vuex';
import { ChatService } from '@/services/chat.service.js';

import WeekNone from '@/components/10week/WeekNone';
import WeekSingle from '@/components/10week/WeekSingle';
import WeekText from '@/components/10week/WeekText';

import FollowupV5 from '@/components/10week/version/FollowupV5';

import audio from '@/assets/notifications/pristine.mp3';
const notificationsound = new Audio(audio);
import infiniteScroll from 'vue-infinite-scroll';
import gql from 'graphql-tag';

// utils
import {
  isFileSupported,
  isFileImage,
  transformFileForUpload,
} from '@/utils/file';
import { watchResizeObserver } from '@corefront/utils/watchResizeObserver';
import { getElementHeight } from '@corefront/utils/getElementHeight';

// constants
import { MAX_SIZE_MB, EXCLUDED_EXT } from '@/constants/files';

export default {
  directives: {
    infiniteScroll,
  },
  components: {
    WeekNone,
    WeekSingle,
    WeekText,
    FollowupV5,
  },
  props: {
    userid: {
      type: String,
      default: localStorage.getItem('userid'),
    },
    conversation: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      adjustTextArea: false,
      prescriptionMessage: {},
      followUpMessage: [],
      followUpType: 'treatment',
      followUpID: '',
      followUpAnswers: [],
      previousFollowUpID: '',
      firstAnswer: null,
      secondQuestionBody: {},
      composite: false,
      newMessage: '',
      isSending: false,
      messages: [],
      conversationId: '',
      nextPage: null,
      attachments: [],
      files: [],
      imgUpload: false,
      height: '',
      messageHeight: 0,
      radioAnswer: '',
      alert: {
        followup: {
          show: false,
          type: 'warning',
          message: '',
        },
        connection: {
          show: false,
          type: 'warning',
          message: '',
        },
      },
      hasError: false,
      conversationmemberlist: [],
      loadingMessage: false,
      isIos: false,
      mobileClass: '',
      textLabel: true,
      followupQuestionVersion: '',
      symptoms: [],
      currentFollowupStep: 0,
      buttonDisabled: false,
      followupV5Key: 0,
      amountToGroupBy: 1,
      messageReceivedFn: null,
      isToastVisible: false,
      isUploading: false,
      threadHeight: 0,
    };
  },
  computed: {
    resultQuery() {
      this.listMessage(this.conversation.id);
      return this.conversation;
    },
    isMessageBodyEmpty() {
      if (this.newMessage) {
        return this.newMessage.trim() === '';
      } else {
        // Check if there's a file included during sending
        return !(this.files.length > 0);
      }
    },
    isConversationRestricted() {
      const { conversation } = this;

      return conversation && conversation.isbanned && !this.isBanWithinOneMonth;
    },
    isBanWithinOneMonth() {
      if (!this.conversation.isbanned) {
        return true;
      }

      const current = moment();
      const provided = moment.unix(this.conversation.banned_date);
      const dayDiff = current.diff(provided, 'days');

      return dayDiff <= 30;
    },
    groupMessage() {
      const messages = this.messages.reduce((groupedMessages, chat) => {
        const sentAtFormatted = moment
          .unix(chat.sentat)
          .format('MMM DD, YYYY hh:mm A');

        if (!groupedMessages[sentAtFormatted]) {
          groupedMessages[sentAtFormatted] = [];
        }

        groupedMessages[sentAtFormatted].push(chat);

        groupedMessages[sentAtFormatted].sort((a, b) => {
          const timestampA = a.sentat;
          const timestampB = b.sentat;

          return timestampA - timestampB;
        });

        return groupedMessages;
      }, {});

      const sortedMessagesArray = Object.entries(messages).sort(
        (a, b) =>
          moment(a[0], 'MMM DD, YYYY hh:mm A').valueOf() -
          moment(b[0], 'MMM DD, YYYY hh:mm A').valueOf()
      );

      const sortedMessagesObject = sortedMessagesArray.reduce(
        (obj, [key, value]) => {
          obj[key] = value;
          return obj;
        },
        {}
      );

      return sortedMessagesObject;
    },
    ...mapState('message', ['unreadMessagesCount']),
    ...mapGetters('socket', {
      socket: 'getSocket',
    }),
  },
  watch: {
    followUpMessage() {
      if (this.followUpMessage.length > 0) {
        if (
          ['symptom', 'sideeffect'].includes(
            this.followUpMessage[0]?.treatmentoption
          )
        ) {
          this.followUpType = 'symptoms';
        } else if (
          ['recommendation', 'methodchange_recommendation'].includes(
            this.followUpMessage[0]?.answerlookup
          )
        ) {
          this.followUpType = 'treatmentform';
        } else {
          this.followUpType = this.followUpMessage[0].answertype;
        }
      }
    },
  },
  async mounted() {
    watchResizeObserver(
      ['#message-input-area', '#chat-headline', '#navbar'],
      entries => {
        const [msgInputAreaEntry, chatHeadlineEntry, navbarEntry] = entries;

        const chatHeadlineHeight = getElementHeight(chatHeadlineEntry.target);
        const navbarHeight = getElementHeight(navbarEntry.target);
        const msgInputAreaHeight = getElementHeight(msgInputAreaEntry.target);

        this.threadHeight =
          window.innerHeight -
          chatHeadlineHeight -
          navbarHeight -
          msgInputAreaHeight;
      }
    );

    this.listMessage(this.conversation.id);
    if (process.env.VUE_APP_MESSAGING_VERSION === 'v2') {
      this.connectToSocket();
    }

    this.matchHeight();
    const container = document.body;
    if (this.$route.name == 'messages') {
      container.classList.add('no-scroll');
    } else {
      container.classList.remove('no-scroll');
    }
    // /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform)
    const isIphone = /(iPhone)/i.test(navigator.platform);
    if (navigator.userAgent.toLowerCase().indexOf('android') > -1) {
      this.mobileClass = 'isAndroid';
      this.isIos = false;
    }
    if (isIphone) {
      this.mobileClass = 'isIos';
      this.isIos = true;
    }

    if (process.env.VUE_APP_MESSAGING_VERSION === 'v2') {
      await ChatService.markAsRead(this.conversation.id);
    }

    this.$store.dispatch('message/resetDoctorMessagesCount');
  },
  methods: {
    setFocus() {
      // Note, you need to add a ref="search" attribute to your input.
      this.$refs.typeMessage.focus();
      this.textLabel = false;
    },
    hideLabel() {
      this.textLabel = true;
      console.log('hide label', this.textLabel);
    },
    getDoctorName(userid) {
      const member = this.conversationmemberlist.find(x => x.userid === userid);
      if (member?.doctorname) {
        return `Dr. ${member.doctorname}`;
      } else {
        return '';
      }
    },
    getDoctorID(userid) {
      const member = this.conversationmemberlist.find(x => x.userid === userid);
      if (member?.doctorid) {
        return member.doctorid;
      } else {
        return null;
      }
    },
    showFollowUpAlert(message, type = null) {
      if (!type) type = 'warning';
      this.alert.followup = {
        show: true,
        type: 'warning',
        message: message,
      };
    },
    focusText(addRemoveClass, className) {
      const el = document.querySelector('.chat-box');
      if (addRemoveClass === 'addClass') {
        el.classList.add(className);
        this.adjustTextArea = true;
      } else {
        el.classList.remove(className);
        this.adjustTextArea = false;
      }
    },
    observeTextarea() {
      this.messageHeight = this.$refs.sendMessage.clientHeight - 75;
    },
    matchHeight() {
      const appHeight = window.innerHeight;
      const messageheight = this.$refs.sendMessage.clientHeight * 2 - 10;
      this.height = appHeight - messageheight;
      // console.log(messageheight);
    },
    openUpload() {
      this.$refs.fileInput.click();
    },
    async handleFilesUpload() {
      const uploadedFiles = this.$refs.fileInput.files;
      this.imgUpload = true;
      const maxFileSize = MAX_SIZE_MB;
      const excludedExtensions = EXCLUDED_EXT;

      const invalidFiles = [];
      const oversizedFiles = [];

      for (var i = 0; i < uploadedFiles.length; i++) {
        const fileSize = uploadedFiles[i].size;
        const fileSizeInMB = fileSize / (1024 * 1024);
        const formattedSize = fileSizeInMB.toFixed(1);
        const validSize = parseFloat(formattedSize) <= maxFileSize;

        if (
          !validSize ||
          !isFileSupported(uploadedFiles[i]) ||
          excludedExtensions.some(ext => uploadedFiles[i].name.endsWith(ext))
        ) {
          invalidFiles.push(uploadedFiles[i]);
        } else {
          let convertedFile;
          convertedFile = await transformFileForUpload(uploadedFiles[i]);
          this.files.push(convertedFile);
          convertedFile.url = URL.createObjectURL(convertedFile);
          this.attachments.push(convertedFile);
        }

        if (!validSize) {
          oversizedFiles.push(uploadedFiles[i]);
        }
      }

      const dataTransfer = new DataTransfer();

      this.files.forEach(file => {
        dataTransfer.items.add(file);
      });

      this.$refs.fileInput.files = dataTransfer.files;

      if (oversizedFiles.length > 0 && invalidFiles.length > 0) {
        this.$bvToast.toast(
          'Please upload files that are less than or equal to 29MB in size, and only TIFF, PNG, JPEG, JPG, TIF, HEIC, HEIF, and PDF are allowed.',
          {
            variant: 'danger',
            noCloseButton: true,
            autoHideDelay: 2100,
            toaster: 'b-toaster-bottom-center',
          }
        );
      } else if (invalidFiles.length > 0) {
        this.$bvToast.toast(
          'Some files are invalid. Only TIFF, PNG, JPEG, JPG, TIF, HEIC, HEIF, and PDF are allowed.',
          {
            variant: 'danger',
            noCloseButton: true,
            autoHideDelay: 2100,
            toaster: 'b-toaster-bottom-center',
          }
        );
      }
    },
    async performMessageAction(messageid, action) {
      try {
        await ChatService.performMessageAction(this.conversationId, messageid, {
          action: action,
        });
      } catch {}
    },
    setPrescriptionMessage(messageid) {
      const message = this.messages.find(x => x.id === messageid);
      this.prescriptionMessage = message;
    },
    async setFollowUpMessage(messageid, step = 1) {
      this.composite = false;
      this.followUpID = messageid;
      try {
        // let message = this.messages.find((x) => x.id === messageid);
        // this.followUpMessage = message;
        // this.followUpMessage = {};
        this.secondQuestionBody = {};
        this.alert.followup.show = false;
        this.alert.followup.message = '';
        const followup = await ChatService.getFollowUpQuestion(messageid, step);
        // Answer followupquestion if recommendation engine has an empty answerlist
        if (
          ['recommendation', 'methodchange_recommendation'].includes(
            followup.data?.[0]?.answerlookup
          ) &&
          followup.data?.[0]?.answerlist.length === 0
        ) {
          this.answerFollowUpQuestion(
            followup.data?.[0]?.id,
            [],
            followup.data?.[0]
          );
          return;
        }

        if (followup.data) {
          const fmessage = this.messages.find(x => x.id === messageid);
          this.followUpMessage = followup.data;
          this.followUpMessage.actionstatus = fmessage.actionstatus;
          if (followup.data[0].hasOwnProperty('answer')) {
            this.firstAnswer = followup.data[0].answer;
          }
        }
        // alert(JSON.stringify(this.followUpMessage))
      } catch {
      } finally {
        this.$refs.modal.$children[0].show();
      }
    },
    async answerFollowUpQuestion(
      id,
      answer,
      answerBody = null,
      moreQuestions,
      treatmentformanswer
    ) {
      try {
        // let answers = []
        // for(let item of this.followUpMessage.questions){
        //   let record = {
        //     "questionid": item.id,
        //     "answer": item.answer || ""
        //   }
        //   answers.push(record)
        // }
        const messageid = this.followUpID;
        // * Previous answer overwriting
        this.followUpAnswers = this.followUpAnswers.filter(o => {
          return o.questionid !== id;
        });
        if (id == this.followUpMessage[0].id) {
          this.firstAnswer = answer;
        }
        if (
          !!this.followUpMessage &&
          !!answerBody &&
          answerBody.hasOwnProperty('inline') &&
          answerBody.inline
        ) {
          this.followUpAnswers = [];
          this.composite = true;
          this.previousFollowUpID = id;
          const CompositeFollowUpRequest =
            await ChatService.getFollowUpQuestion(messageid, answerBody.next);
          this.secondQuestionBody = CompositeFollowUpRequest.data[0];
          this.followUpAnswers.push({ questionid: id, answer: answer });
        } else {
          this.followUpAnswers.push({
            questionid: id,
            answer: answer,
            ...(moreQuestions && { others: [moreQuestions] }),
          });
          // let exceptions = [1.2, 4.1, 3.3];
          // if (
          //   id % 1 != 0 &&
          //   this.followUpAnswers.length < 2 &&
          //   !exceptions.includes(id)
          // ) {
          //   this.followUpAnswers.push({
          //     questionid: this.followUpMessage[0].id,
          //     answer: this.followUpMessage[0].answer,
          //   });
          // }
          // let rootID = exceptions.includes(id) ? id : Math.floor(id);
          // console.log(this.followUpAnswers);
          const rootID =
            this.followUpAnswers.length > 1 ? this.previousFollowUpID : id;
          await ChatService.answerFollowUpQuestion(messageid, rootID, {
            answers: this.followUpAnswers,
          })
            .then(res => {
              this.followUpAnswers = [];
              if (res.data.next == -1) {
                this.$refs.modal.$children[0].hide();
                return;
              } else {
                if (
                  answerBody?.type === 'treatmentform' &&
                  answerBody?.answerlist.length !== 0
                ) {
                  const treatmentFollowUpAnswer = [
                    {
                      questionid: 11,
                      answer: treatmentformanswer ? 'yes' : 'unsure',
                    },
                  ];
                  ChatService.answerFollowUpQuestion(messageid, 11, {
                    answers: treatmentFollowUpAnswer,
                  }).then(questionresponse => {
                    this.setFollowUpMessage(
                      this.followUpID,
                      questionresponse.data.next
                    );
                    this.currentFollowupStep = questionresponse.data.next;
                  });
                } else {
                  this.setFollowUpMessage(this.followUpID, res.data.next);
                  this.currentFollowupStep = res.data.next;
                }
              }
              // this.$root.$emit("bv::hide::modal", "weekNotice");
            })
            .catch(err => {
              this.showFollowUpAlert(err.message);
            });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getPreviousFollowUpQuestion() {
      if (this.followUpMessage[0].id == 1) {
        this.$refs.modal.$children[0].hide();
      } else {
        this.composite = false;
        this.followUpAnswers = [];
        this.secondQuestionBody = {};
        const res = await ChatService.getPreviousFollowUpQuestion(
          this.followUpID,
          this.followUpMessage[0].id
        );
        if (res.data.length > 0) {
          this.currentFollowupStep = res.data[0].id;
        }
        if (res.data) {
          this.followUpMessage = res.data;
          if (res.data.length > 1) {
            this.composite = true;
            this.firstAnswer = res.data[0].answer;
            this.secondQuestionBody = res.data[1];
          }
        }
      }
    },
    removeFile(key) {
      this.attachments.splice(key, 1);
      this.files.splice(key, 1);
      if (this.attachments.length == 0) {
        this.attachments = [];
        this.files = [];
        this.imgUpload = false;
      }
    },
    async sendMessage() {
      try {
        if (!navigator.onLine) {
          if (this.isToastVisible) {
            return;
          }

          this.isToastVisible = true;

          this.$bvToast.toast('No internet connection. Message not sent.', {
            variant: 'warning',
            noCloseButton: true,
            toaster: 'b-toaster-bottom-center',
            autoHideDelay: 2000,
          });

          setTimeout(() => {
            this.isToastVisible = false;
            this.isUploading = false;
          }, 2000);

          return;
        }

        if (this.isMessageBodyEmpty) {
          if (!(this.files.length > 0)) {
            return;
          }
        }
        if (this.isSending === false) {
          this.isSending = true;
          if (process.env.VUE_APP_MESSAGING_VERSION === 'v2') {
            const obj = {
              id: uuidv4(),
              conversationId: this.conversation.id,
              sentAt: Math.floor(Date.now() / 1000),
              message: this.newMessage,
            };
            if (this.files.length > 0) {
              this.isUploading = true;
              obj.attachment = Object.keys(this.files).map(key => ({
                name: this.files[key].name,
                size: this.files[key].size,
                type: this.files[key].type,
                file: this.files[key],
              }));
              this.socket.emit('new_message', obj);
            } else {
              this.socket.emit('new_message', obj);
            }
          } else {
            this.isUploading = true;
            const obj = { id: this.conversation.id, message: this.newMessage };
            if (this.files.length > 0) {
              const formData = new FormData();
              for (let i = 0; i < this.files.length; i++) {
                const file = this.files[i];
                formData.append('attachment', file);
              }
              formData.append('message', this.newMessage || '');
              obj.attachment = formData;
              const res = await ChatService.sendMessageWithAttachment(
                this.conversation.id,
                formData,
                {
                  headers: {
                    'Content-Type': 'multipart/form-data',
                  },
                }
              );
            } else {
              const res = await ChatService.sendMessage(obj);
              console.log('Sent Message', res);
            }
          }
        }
      } catch (error) {
        console.log(error.message);
      } finally {
        if (navigator.onLine) {
          this.$store.commit('message/SET_UNREAD_COUNT', 0);
          // this.$store.dispatch("message/getUnreadMessageCount");
          this.isSending = false;
          this.newMessage = '';
          this.attachments = [];
          this.files = [];
          this.imgUpload = false;
          this.$refs.fileInput.value = '';
        }
      }
    },
    async listMessage(conversationid) {
      try {
        this.nextPage = null;
        this.conversationId = conversationid;
        this.loadingMessage = true;
        const { data: messages } = await ChatService.listMessage(
          conversationid,
          this.nextPage
        );
        this.messages = messages.items;
        this.nextPage = messages.nextpage;
        ChatService.listConversationMember(conversationid)
          .then(({ data: members }) => {
            this.conversationmemberlist = members;
          })
          .finally(() => {
            this.loadingMessage = false;
          });
        if (this.unreadMessagesCount > 0) {
          this.$store.commit('message/SET_UNREAD_COUNT', 0);
          // this.$store.dispatch("message/getUnreadMessageCount");
        }
      } catch (error) {
        console.log(error.message);
      }
    },
    getMemberProfileUrl(userid) {
      const member = this.conversationmemberlist.find(x => x.userid === userid);
      if (member) {
        return member.profileUrl;
      } else {
        return '';
      }
    },
    getMemberName(userid) {
      const member = this.conversationmemberlist.find(x => x.userid === userid);
      if (member) {
        return `Dr. ${member.name}`;
      } else {
        return '';
      }
    },
    // viewMsg (index) {
    //   this.msgSelected = index
    // },
    // searchTerm (value) {
    //   return this.filter.toLowerCase().split(' ').every(v => value.toString().toLowerCase().includes(v))
    // },
    async messageScroll(e) {
      const scroll =
        e.target.scrollTop + e.target.scrollHeight === e.target.clientHeight;
      const scrollwithallowance =
        e.target.scrollTop + e.target.scrollHeight < e.target.clientHeight + 10;
      if (scroll || scrollwithallowance) {
        if (this.nextPage && !this.loadingMessage) {
          this.loadingMessage = true;
          const { data: messages } = await ChatService.listMessage(
            this.conversationId,
            this.nextPage
          );
          for (let i = 0; i < messages.items.length; i++) {
            const message = messages.items[i];
            this.messages.push(message);
          }
          if (messages.nextpage) {
            this.nextPage = messages.nextpage;
          } else {
            this.nextPage = null;
          }
          this.loadingMessage = false;
        }
      }
    },
    reconnectApollo() {
      location.reload();
    },
    async onFollowUpStart(chat) {
      if (chat?.custommessage?.message?.followupquestionversion === 'v5') {
        await this.$refs.modalv5.setFollowUpMessage(chat.id, 1);
        this.$refs.modalv5.$children[0].$children[0].show();
      } else {
        this.setFollowUpMessage(chat.id, 1);
        this.followupQuestionVersion =
          chat?.custommessage?.message?.followupquestionversion;
      }
    },
    connectToSocket() {
      if (this.socket) {
        this.socket.on('validation_error', err => {
          console.log(err);
        });

        this.socket.on('message_delivery_confirmed', message => {
          console.log('Confirmed: ', message);
          this.onMessageReceived(message);
        });

        this.messageReceivedFn = message => {
          if (message.conversationid === this.conversation.id) {
            this.onMessageReceived(message);
            console.log('Received message', message);
          }
        };

        this.socket.on('message', this.messageReceivedFn);

        this.socket.on(
          'conversation:member_active_status_changed',
          async message => {
            console.log(message);
            location.reload();
          }
        );
      }
    },
    onMessageReceived(message) {
      this.isUploading = true;
      this.hasError = false;
      this.isUploading = false;
      if (process.env.VUE_APP_MESSAGING_VERSION === 'v2') {
        ChatService.markAsRead(this.conversation.id);
      }
      if (message.action === 'SENT') {
        const newmessage = message;
        if (newmessage.userid !== this.userid) {
          notificationsound.play();
        }
        if (
          newmessage.type === 'prescription_confirmation' ||
          newmessage.type === 'prescription_followup'
        ) {
          if (newmessage.custommessage.constructor.name === 'String') {
            newmessage.custommessage = JSON.parse(newmessage.custommessage);
          }
        }
        this.messages.splice(0, 0, newmessage);
        return this.messages;
      } else if (message.action === 'UPDATED') {
        const messageupdate = message;
        const messagetoupdate = this.messages.find(
          x => x.id === messageupdate.id
        );
        if (messagetoupdate != undefined) {
          if (messagetoupdate.type === 'prescription_confirmation') {
            messagetoupdate.actionstatus = messageupdate.actionstatus;
            notificationsound.play();
          } else if (messagetoupdate.type === 'prescription_followup') {
            messagetoupdate.custommessage = JSON.parse(
              messageupdate.custommessage
            );
            messagetoupdate.actionstatus = messageupdate.actionstatus;
            notificationsound.play();
          }
        }
      }
    },
    groupBy(arr, n) {
      if (arr) {
        const group = [];
        for (let i = 0, end = arr.length / n; i < end; ++i)
          group.push(arr.slice(i * n, (i + 1) * n));
        return group;
      } else {
        return null;
      }
    },
    formattedTimestamp(originalTimestamp) {
      return moment(originalTimestamp, 'MMM DD, YYYY hh:mm A')
        .format('MMMM DD, hh:mm A')
        .toUpperCase();
    },
    viewImage(file) {
      return isFileImage(file);
    },
  },
  apollo: {
    $subscribe: {
      message: {
        query: gql`
          subscription onMessageActivity($conversationid: String!) {
            onMessageActivity(conversationid: $conversationid) {
              id
              sentat
              conversationid
              type
              userid
              message
              action
              actionstatus
              custommessage
              attachment {
                filename
                id
                key
                size
                type
                url
              }
            }
          }
        `,
        error(error) {
          console.error(error);
        },
        variables() {
          return {
            conversationid: this.conversationId,
          };
        },
        result({ data }) {
          console.log('AppSync Event', data);
          this.hasError = false;
          const obj = data;
          if (obj.onMessageActivity.action === 'SENT') {
            const newmessage = obj.onMessageActivity;
            if (newmessage.userid !== this.userid) {
              notificationsound.play();
            }
            if (
              newmessage.type === 'prescription_confirmation' ||
              newmessage.type === 'prescription_followup'
            ) {
              if (newmessage.custommessage.constructor.name === 'String') {
                newmessage.custommessage = JSON.parse(newmessage.custommessage);
              }
            }
            this.messages.splice(0, 0, newmessage);
            return this.messages;
          } else if (obj.onMessageActivity.action === 'UPDATED') {
            const messageupdate = obj.onMessageActivity;
            const messagetoupdate = this.messages.find(
              x => x.id === messageupdate.id
            );
            if (messagetoupdate != undefined) {
              if (messagetoupdate.type === 'prescription_confirmation') {
                messagetoupdate.actionstatus = messageupdate.actionstatus;
                notificationsound.play();
              } else if (messagetoupdate.type === 'prescription_followup') {
                messagetoupdate.custommessage = JSON.parse(
                  messageupdate.custommessage
                );
                messagetoupdate.actionstatus = messageupdate.actionstatus;
                notificationsound.play();
              }
            }
          }
        },
        skip() {
          return false;
        },
        error(err) {
          // alert(err?.errors?.[0]?.message);
          if (err?.errors?.[0]?.message.includes('UnauthorizedException')) {
            this.alert.connection.show = true;
          }
          this.hasError = true;
          console.error('err', err);
        },
      },
    },
  },
  destroyed() {
    if (this.messageReceivedFn) {
      this.socket.off('message', this.messageReceivedFn);
    }
  },
};
</script>

<style>
.modal-content.cancellation-main {
  background-color: #1a6a72 !important;
}

.text-plum {
  color: #cdfcb1 !important;
}
</style>

<style scoped>
::v-deep .intercom-btn {
  border: 1px solid #19191c;
}

::v-deep body {
  overflow-y: hidden !important;
}
</style>

<style lang="scss" scoped>
.textarea-label {
  position: absolute;
  top: 0;
  left: 0;
  right: 40px;
  bottom: 0;
  z-index: 1;
  margin-bottom: 0;
  padding-left: 10px;
  display: flex;
  align-items: center;
  font-size: 14px !important;
}

.intercom-button {
  @include media-breakpoint-down(md) {
    display: none !important;
  }
}

.message-holder {
  display: flex;
  padding: 0 10px;
  max-width: 1006px;
  width: 100%;
  height: 52px;

  .textarea-box {
    display: flex;
    flex-grow: 1;
    align-items: center;
    background-color: #f8f8f8;
    border-radius: 15px;

    div:first-child {
      position: relative;
      flex-grow: 1;
    }

    div:last-child {
      flex: none;
    }
  }

  .button-holder {
    padding-left: 10px;
  }
}

.chat-textbox {
  background-color: #f8f8f8;
  font-size: 14px !important;
  border: none;
  width: 100%;
  resize: none;
  margin-left: 10px;
  outline: none;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: #828282;
  }
}

.chat-holder {
  display: flex;
}

.chat-headlines {
  height: 70px;
  display: flex;
  align-items: center;

  @include media-breakpoint-down(md) {
    position: fixed;
    left: 0;
    right: 0;
    background-color: white;
    z-index: 2;
  }
  > div {
    margin: 0 auto;
    width: 90%;
    display: flex;
    justify-content: space-between;
  }
}

.new-prescription {
  background-color: #e9f5f3;
}

.products {
  display: flex;
  align-items: stretch;
  background-color: #f9f9f9;
  .product-img {
    background-color: #e8f2f1;
    width: 165px;
    max-width: 165px;
    height: 155px;
    flex: 1 0 165px;
    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }
  .product-content {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    padding: 1rem;
    margin-bottom: 0;
    list-style-type: none;
    list-style: none;
  }
}
// flex: 1;
// min-width: 0; // fix text-truncate
/* width */
.chat-container,
.chat-box-container {
  transition: height 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.chat-box-container {
  display: flex;
  flex-flow: column;
  width: 100%;
  .chat-box {
    flex-grow: 1;
    width: 100%;
  }
  .message {
    flex: none;
  }
}
.chat-box {
  display: flex;
  margin: 0 auto;
  padding: 1rem;
  overflow-y: scroll;
  // height: 100%;
  flex-flow: column-reverse;
  background-color: #f9f9f9;
  // &.textarea-expand {
  //   min-height: calc(100% - 85px);
  //   max-height: calc(100% - 85px);
  // }
  .chat-holder {
    width: 100%;
    max-width: 1140px;
    margin: 0 auto;
    display: flex;
    flex-flow: column-reverse;
    @include media-breakpoint-down(md) {
      width: 100%;
    }

    .chat {
      margin: 0.5rem 0;
    }
  }

  @include media-breakpoint-down(md) {
    margin-top: 70px;
  }

  .chat-bubble {
    display: flex;
    padding: 0.5rem 0 0;
    &.doctor {
      flex-flow: column;
      align-items: flex-start;
      justify-content: flex-start;
    }
    &.patient {
      flex-flow: column;
      align-items: flex-end;
      justify-content: flex-end;
    }
    .msg {
      flex: 0 auto;
      max-width: 60%;
      padding: 0.75rem 1rem;
      background-color: #e6e6e6;
      white-space: pre-line;
      word-break: break-word;
      p {
        font-size: 14px;
        line-height: 20px !important;
      }
      .img-thumbnail {
        border: 0;
        border-radius: 0;
      }
      &.patient {
        margin-left: 10px;
        margin-right: 0;
        background-color: #c1d7d9;
        // color: $secondary;
      }
    }
    // small {
    //   align-self: flex-end;
    //   margin-left: 10px;
    // }
  }
  .chat-img {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    &.is-doctor {
      justify-content: flex-start;
    }
    .img-holder {
      flex: 50px 0 0;
      width: 50px;
      height: 50px;
      border-radius: 50px;
      margin-right: 15px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
.pre-upload {
  // position: absolute;
  // bottom: 70px;
  // border-top: 1px solid rgba($primary, 0.015);
  display: flex;
  margin-top: 7px;
  padding-top: 5px;
  padding-left: 10px;
  padding-bottom: 5px;
  // background-color: $white;
  .img-holder {
    position: relative;
    width: 50px;
    height: 50px;
    margin-right: 10px;
    border: 1px solid $border-color;
    display: flex;
    align-items: center;
    justify-content: center;
    .b-icon.bi {
      position: absolute;
      top: -7px;
      right: -7px;
      cursor: pointer;
    }
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  + .card-footer {
    border-top: 0;
  }
}
.message {
  // position: absolute;
  // bottom: 0;
  // left: 0;
  padding: 15px 0;
  background-color: #e6e6e6;
  border-top: 1px solid #e0e0e0;
  // @include media-breakpoint-down(sm) {
  //   position: fixed;
  //   bottom: 0;
  //   width: 100%;
  // }
}
.msg-type {
  margin: 0 auto;
  width: 100%;
}
// @media (min-width: 992px) {
//   .chat-box {
//     .chat-holder {
//       width: 1024px;
//       // margin: 0 auto;
//     }
//   }
//   .msg-type {
//     width: 80%;
//   }
// }
.pending-btn {
  background-color: #c7bdff;
  color: #2b2660;
  border-color: #c7bdff;
  @include media-breakpoint-down(sm) {
    min-width: none;
    width: 100%;
    &.decline {
      margin-top: 1rem;
    }
  }
}

:deep(p) {
  display: block;
  font-size: 14px;
  line-height: 20px !important;
  a {
    text-decoration: underline;
  }
}

:deep(p):empty:before {
  content: " ";
  white-space: pre;
}

:deep(p:last-of-type) {
  margin-bottom: 0;
}
</style>
